

import {
	TX_ORDER_STATE_COMPLETE,
	TX_ORDER_STATE_OPEN,
	TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS,
	TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS_CUSTOMER, 
	TX_ORDER_STATUS_COMPLETE,
	TX_ORDER_STATUS_COMPLETE_CUSTOMER, 
	TX_ORDER_STATUS_PENDING,
	TX_ORDER_STATUS_PENDING_CUSTOMER, 
	TX_ORDER_STATUS_PENDING_PAYMENT,
	TX_ORDER_STATUS_PREORDER,
	TX_ORDER_STATUS_READY_FOR_PICKUP,
	TX_ORDER_STATUS_READY_TO_GRADE,
	TX_ORDER_STATUS_READY_TO_GRADE_CUSTOMER,
} from './strings';


// Order export (invoice by default) constants

export const ORDER_EXPORT_ADDRESS_COLUMN_WIDTH = 360;
export const ORDER_EXPORT_ADDRESS_ROW_HEIGHT = 120;

export const ORDER_EXPORT_BACKGROUND_COLOR = '#FFFFFF';

export const ORDER_EXPORT_FONT_FAMILY = 'helvetica';
export const ORDER_EXPORT_FONT_SIZE = 11;

export const ORDER_EXPORT_FOOTER_HR_HEIGHT = 2;
export const ORDER_EXPORT_FOOTER_ORDER_SECTION_WIDTH = 0.5;
export const ORDER_EXPORT_FOOTER_PAYMENT_SECTION_WIDTH = 0.5;
export const ORDER_EXPORT_FOOTER_PAYMENT_SECTION_COST_WIDTH = 0.33;

export const ORDER_EXPORT_HEADER_COLOR = '#4B4B4B';
export const ORDER_EXPORT_HEADER_COLOR_LIGHT = '#A4A4A4';
export const ORDER_EXPORT_HEADER_TEXT_COLOR = '#FFFFFF';

export const ORDER_EXPORT_HEADER_FONT_SIZE = 10;
export const ORDER_EXPORT_HEADER_HEIGHT = 18;

export const ORDER_EXPORT_PAGE_MARGIN = 20;

export const ORDER_EXPORT_SECTION_MARGIN = 16;

export const ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_TOP = 180;
export const ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_LEFT = 36;

export const ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT = '#F2F2F2';
export const ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE = 10;
export const ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL = 8;

export const ORDER_EXPORT_TABLE_COLUMN_QUANTITY_WIDTH = 0.10;
export const ORDER_EXPORT_TABLE_COLUMN_NAME_WIDTH = 0.70;
export const ORDER_EXPORT_TABLE_COLUMN_PRICE_WIDTH = 0.18;

export const ORDER_EXPORT_TABLE_INVENTORY_WIDTH = 144;

export const ORDER_EXPORT_TEXT_COLOR = '#000000';


// Pull-sheet specific constants

export const ORDER_EXPORT_HEADER_TITLE_FONT_SIZE = 26;
export const ORDER_EXPORT_HEADER_SUBTITLE_FONT_SIZE = 12;

export const ORDER_EXPORT_ROW_ALLOTMENT = 54;

export const ORDER_EXPORT_SECTION_TITLE_FONT_SIZE = 11;

export const ORDER_EXPORT_SECTION_ROW_QTY_KEY = 'qty';
export const ORDER_EXPORT_SECTION_ROW_QTY_WIDTH = 36;

export const ORDER_EXPORT_SECTION_ROW_NUMBER_KEY = 'number';
export const ORDER_EXPORT_SECTION_ROW_NUMBER_WIDTH = 108;

export const ORDER_EXPORT_SECTION_ROW_NAME_KEY = 'name';
export const ORDER_EXPORT_SECTION_ROW_NAME_WIDTH = 196;

export const ORDER_EXPORT_SECTION_ROW_DETAIL_WIDTH = 232;

export const ORDER_EXPORT_SECTION_ROW_INVENTORY_KEY = 'inv';


// Order item grade types

export const ORDER_ITEM_GRADE_TYPE_CONFIRM = 'confirm';
export const ORDER_ITEM_GRADE_TYPE_MODIFIED = 'modified';
export const ORDER_ITEM_GRADE_TYPE_REMOVED = 'removed';



// Order status classes

export const ORDER_STATUS_KEY_PENDING = 'pending';
export const ORDER_STATUS_KEY_PREORDER = 'preorder';
export const ORDER_STATUS_KEY_SHIPPED = 'shipped';
export const ORDER_STATUS_KEY_WAITING_FOR_ITEMS = 'waiting';
export const ORDER_STATUS_KEY_READY_TO_GRADE = 'ready-for-grading';
export const ORDER_STATUS_KEY_PENDING_PAYMENT = 'pending-payment';
export const ORDER_STATUS_KEY_READY_FOR_PICKUP = 'ready-pickup';


export const ORDER_STATUS_CLASS_ERROR = 'error';
export const ORDER_STATUS_CLASS_NULL = 'null';
export const ORDER_STATUS_CLASS_SUFFIX_ERROR = 'Error';
export const ORDER_STATUS_CLASS_SUFFIX_WARNING = 'Warning';



// Orders states (macro/parent category)

export const ORDER_STATE_COMPLETE = {
	key: 'complete',
	name: TX_ORDER_STATE_COMPLETE,
}

export const ORDER_STATE_OPEN = {
	key: 'open',
	name: TX_ORDER_STATE_OPEN,
}





// Orders statuses (micro/child category)

// Right now, the key is 'shipped', but displays as complete; should resolve this
export const ORDER_STATUS_COMPLETE = {
	key: ORDER_STATUS_KEY_SHIPPED,
	name: TX_ORDER_STATUS_COMPLETE,
	customerName: TX_ORDER_STATUS_COMPLETE_CUSTOMER,
	state: ORDER_STATE_COMPLETE,
}

export const ORDER_STATUS_PENDING = {
	key: ORDER_STATUS_KEY_PENDING,
	name: TX_ORDER_STATUS_PENDING,
	customerName: TX_ORDER_STATUS_PENDING_CUSTOMER,
	state: ORDER_STATE_OPEN,
}

export const ORDER_STATUS_PREORDER = {
	key: ORDER_STATUS_KEY_PREORDER,
	name: TX_ORDER_STATUS_PREORDER,
	customerName: TX_ORDER_STATUS_PREORDER,
	state: ORDER_STATE_OPEN,
}

export const ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS = {
	key: ORDER_STATUS_KEY_WAITING_FOR_ITEMS,
	name: TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS,
	customerName: TX_ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS_CUSTOMER,
	state: TX_ORDER_STATE_OPEN,
}

export const ORDER_STATUS_BUYLIST_READY_TO_GRADE = {
	key: ORDER_STATUS_KEY_READY_TO_GRADE,
	name: TX_ORDER_STATUS_READY_TO_GRADE,
	customerName: TX_ORDER_STATUS_READY_TO_GRADE_CUSTOMER,
	state: TX_ORDER_STATE_OPEN,
}

export const ORDER_STATUS_READY_FOR_PICKUP = {
	key: ORDER_STATUS_KEY_READY_FOR_PICKUP,
	name: TX_ORDER_STATUS_READY_FOR_PICKUP,
}

export const ORDER_STATUS_PENDING_PAYMENT = {
	key: ORDER_STATUS_KEY_PENDING_PAYMENT,
	name: TX_ORDER_STATUS_PENDING_PAYMENT,
}


export const ORDER_STATUS_ALL = [
	ORDER_STATUS_COMPLETE, 
	ORDER_STATUS_PENDING,
	ORDER_STATUS_BUYLIST_WAITING_FOR_ITEMS,
	ORDER_STATUS_BUYLIST_READY_TO_GRADE,
	ORDER_STATUS_PREORDER,
];
























