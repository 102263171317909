import React, { useCallback, useEffect, useRef } from 'react';

import * as d3 from 'd3';

import {
  ANALYTICS_CONFIG_METRIC,
  ANALYTICS_METRIC_TOTAL,
} from '../../constants/analytics';

import { formatPrice } from '../../utils/formatting';

import useResizeObserver from '../../hooks/useResizeObserver';

import LoadingIcon from '../Icons/LoadingIcon';

import './style/_graph.scss';

export const LineGraph = (props) => {
  
  // Props
  const {
    adminTheme, 
    colorMapping, 
    data, 
    dataLoading,
    dataModel,
    disabledSegments,
    height,
    showLegend,
    toggleDisabledSegment,
    productLines,
  } = props;

  // State
  // None yet

  // Refs (general; resize ref underneath drawgraph)
  const graphRef = useRef();
  const graphWrapperRef = useRef();

  // Methods
  const isCurrencyValue = useCallback(() => {
    if(dataModel && dataModel.config) {
      return dataModel.getConfigValue(ANALYTICS_CONFIG_METRIC) === ANALYTICS_METRIC_TOTAL;
    }
    return false;
  }, [ dataModel ]);

  const isActiveProductLine = (lineLabel) => {
    
    // The line label should be the name; if it's a permalink, then we haven't matched that with a product line and is deleted (not active)
    for(const line of productLines) {
      if(line.name === lineLabel) {
        return true;
      }
    }
    return false;
  }

  const drawGraph = useCallback(() => {

    if(!graphRef.current || !graphWrapperRef.current) {
      return null;
    }
  
    // Size parameters
    const width = graphWrapperRef && graphWrapperRef.current ? graphWrapperRef.current.clientWidth : 600;
    const marginTop = 30;
    const marginRight = 50;
    const marginBottom = 20;
    const marginLeft = 50;
    const tickSpacing = 80;

    // Prepare the scales for positional and color encodings.
    const x = d3.scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([marginLeft, width - marginRight]);

    const y = d3.scaleLinear()
      // .domain([0, d3.max(series, d => d3.max(d, d => d[1]))]).nice()
      .domain([0, d3.max(data, d => d.value)]).nice()
      .rangeRound([height - marginBottom, marginTop]);

    // Select the SVG container.
    const svg = d3.select(graphRef.current);

    // Clear the existing content
    svg.selectAll('*').remove();

    svg.attr('width', width)
       .attr('height', height)
       .attr('viewBox', [0, 0, width, height])
       .attr('style', 'max-width: 100%; height: auto;');

    // Add the y-axis, remove the domain line, add grid lines and a label.
    svg.append('g')
      .attr('transform', `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).ticks(height / 80))
      .call(g => g.selectAll('.tick line').clone()
        .attr('x2', width - marginLeft - marginRight)
        .attr('stroke-opacity', 0.1))

    // // Append a path for 
    // svg.append("g")
    //   .selectAll()
    //   .data(series)
    //   .join("path")
    //     .attr("fill", d => customColor(d.key))
    //     .attr("d", area)
    //   .append("title")
    //     .text(d => d.key);

    // Append the x-axis axis 
    svg.append('g')
      .attr('transform', `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x).ticks(width/tickSpacing).tickSizeOuter(0));

    // Compute the points in pixel space as [x, y, z], where z is the name of the series.
    const points = data.map((d) => [x(d.date), y(d.value), d.segment]);

    // Group the points by series.
    const groups = d3.rollup(points, v => Object.assign(v, {z: v[0][2]}), d => d[2]);

    const disabledColor = '#DDDDDD';
    // const defaultColor = '#DDDDDD';

    const customColor = (key) => {
      if(Object.keys(colorMapping).length) {
        return colorMapping[key] || 'steelblue';
      } else {
        return 'steelblue'; // Default color
      }
    };

    // Draw the lines.
    const line = d3.line();
    const path = svg.append('g')
      .attr('fill', 'none')
      .attr('stroke-width', 2.5)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .selectAll("path")
      .data(Array.from(groups))
      .join('path')
      .style('mix-blend-mode', 'multiply')
      .attr('stroke', d => customColor(d[0]))
      .attr('d', d => line(d[1]));

    const dot = svg.append('g')
      .attr('display', 'none');

    dot.append('circle')
      .attr('r', 3.5);

    const tooltipText = dot.append('text')
      .attr('text-anchor', 'middle')
      .attr('y', -8)
      .style('font-size', '13px');

    tooltipText.append('tspan')
      .attr('class', 'tooltip-date')
      .style('font-weight', 300);

    tooltipText.append('tspan')
      .attr('class', 'tooltip-value')
      .style('font-weight', 400);

    function pointermoved(event) {
      const [xm, ym] = d3.pointer(event);
      const i = d3.leastIndex(points, ([x, y]) => Math.hypot(x - xm, y - ym));
      const [x, y, k] = points[i];
      const d = data[i];

      path
        .attr('stroke', d => d[0] === k ? customColor(d[0]) : disabledColor)
        .filter(d => d[0] === k)
        .raise();

      dot.attr('transform', `translate(${x},${y})`);
      tooltipText.select('.tooltip-date').text(d3.timeFormat("%b %d")(d.date) + ': ');
      tooltipText.select('.tooltip-value').text(isCurrencyValue() ? formatPrice(d.value) : (parseInt(d.value) || '0'));
      svg.property('value', data[i]).dispatch('input', { bubbles: true });
    }

    function pointerentered() {
      path.style('mix-blend-mode', null).attr('stroke', disabledColor);
      dot.attr('display', null);
    }

    function pointerleft() {
      // path.style('mix-blend-mode', 'multiply').style('stroke', null);
      path.style('mix-blend-mode', 'multiply').attr('stroke', d => customColor(d[0]));
      dot.attr('display', 'none');
      svg.node().value = null;
      svg.dispatch('input', {bubbles: true});
    }

    svg
      .on('pointerenter', pointerentered)
      .on('pointermove', pointermoved)
      .on('pointerleave', pointerleft)
      .on('touchstart', event => event.preventDefault());

    // Check if the pointer is within the SVG bounds after rendering
    // Revisit, xm,ym are sometimes undefined when we need them
    function checkPointerPosition() {
      const [xm, ym] = d3.pointer(svg.node());
      const { width, height } = svg.node().getBoundingClientRect();
      if (xm >= 0 && xm <= width && ym >= 0 && ym <= height) {
        svg.node().dispatchEvent(new PointerEvent('pointerenter', {
          bubbles: true
        }));
      }
    }

    // Call checkPointerPosition after rendering the graph
    checkPointerPosition();

    return svg.node();

  }, [ colorMapping, data, graphWrapperRef, height, isCurrencyValue ]);

  // Resize ref
  const resizeRef = useResizeObserver(drawGraph, { throttle: 100 });

  // Effects
  useEffect(() => {
    if(!dataLoading) {
      drawGraph();
    }
  }, [ dataLoading, drawGraph ]);

  return (
    <div ref={resizeRef} className={`LineGraph Graph ${adminTheme ? 'titanTheme' : ''}`}>
      <div 
        ref={graphWrapperRef}
        className='graphWrapper'
        style={{
          height: height ? `${height}px` : 'auto',
        }}>
        {dataLoading || !data ?
          <div className='graphLoading'>
            <div className='graphLoadingIcon'>
              <LoadingIcon />
            </div>
          </div> :
          <svg ref={graphRef}></svg>
        }
      </div>
      {!dataLoading && showLegend ?
        <div className={`legendWrapper`}>
          {Object.entries(colorMapping).map(([label, color], i) => {

            if(!isActiveProductLine(label)) {
              return null;
            }

            return <div 
                    key={i} 
                    className={`legendElementWrapper ${disabledSegments.includes(label) ? 'disabled' : ''}`}
                    onClick={() => toggleDisabledSegment(label)}>
              <div className='legendElement'>
                <div 
                  className='legendSwatch' 
                  style={!disabledSegments.includes(label) ? { background: color } : {}}>  
                </div>
                <div className={'legendLabel EllipsisElement'}>{label}</div>
              </div>
            </div>
          })}
        </div> :
        null
      }
    </div>
  );
};

export default LineGraph;




