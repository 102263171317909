import 'whatwg-fetch';

import * as x from '../constants/actions';
import * as a from '../constants/api';
import * as n from '../constants/endpoints';
import { ERROR_PRODUCT_IMAGE_UPLOAD } from '../constants/errors';

import { authTokenRefresh } from './auth';

import { 
	LorcanaCard,
	LorcanaSet, 
} from '../models/syncs/lorcana';
import { 
	MagicCard, 
	MagicSet,
} from '../models/syncs/magic';
import { 
	PokemonCard,
	PokemonSet, 
} from '../models/syncs/pokemon';
import { 
	StarwarsCard,
	StarwarsSet, 
} from '../models/syncs/starwars';
import { 
	BulkUpload, 
	BulkUploadResult,
	Inventory,
	Product, 
	ProductAttribute, 
	ProductTag,
} from '../models/products';

import { generateMediaFilename } from '../utils/files';
import { stringFormat } from '../utils/formatting';
import { authReq, getUrlParams, isOk, parseJSON, parseStatus } from '../utils/request';


/******************************
*******  Synchronous  *********
******************************/


export function productSetNameValue(payload) {
  return {
    type: x.ACTION_PRODUCT_SET_NAME_VALUE,
    payload
  };
}

export function productSetProductFilters(payload) {
  return {
    type: x.ACTION_PRODUCT_SET_FILTERS,
    payload
  };
}

export function productSetProductFilterCustomOptions(payload) {
  return {
    type: x.ACTION_PRODUCT_SET_FILTER_CUSTOM_OPTIONS,
    payload
  };
}

export function productClearProductFilters(payload) {
  return {
    type: x.ACTION_PRODUCT_CLEAR_FILTERS,
    payload
  };
}


/******************************
*******  Asynchronous  ********
******************************/



export function productAdd(addData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_ADMIN_ADD;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productEdit(editData, productPermalink, plPermalink) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_EDIT, { 
			permalink: productPermalink, 
			pl_permalink: plPermalink,
		});

		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(editData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productDelete(productPermalink, plPermalink) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_DELETE, { 
			permalink: productPermalink, 
			pl_permalink: plPermalink,
		});
		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productInventoryAdd(addData) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_ADMIN_INVENTORY_ADD;
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Inventory(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productInventoryVariantAdd(addData, productPermalink, plPermalink, signal) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_INVENTORY_VARIANT_ADD, { 
			permalink: productPermalink,
			pl_permalink: plPermalink,
		});
		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(addData),
		  signal
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Inventory(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productInventoryEdit(editData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const urlParams = {
			pl_permalink: editData.pl_permalink,
			permalink: editData.permalink, 
			id: editData.pk,
		}

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_INVENTORY_EDIT, urlParams);
		
		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(editData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Inventory(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchPage(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_ADMIN_GET_PAGE + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const productsArray = [];
	    	for(const prod of resp.data.data) {
	    		productsArray.push(new Product(prod));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: productsArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchDeepPage(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_ADMIN_GET_PAGE_DEEP + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const productsArray = [];
	    	for(const prod of resp.data.data) {
	    		productsArray.push(new Product(prod));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: productsArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchBulkUpload(getParams, uploadUuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_FETCH_BULK_UPLOAD, { uuid: uploadUuid }) + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const resultsArray = [];
	    	for(const res of resp.data.results_data) {
	    		resultsArray.push(new BulkUploadResult(res));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { 
	    		upload: new BulkUpload(resp.data.upload),
	    		results_data: resultsArray,
	    	}));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productBulkUploadFetchPage(getParams, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_ADMIN_GET_BULK_UPLOAD_PAGE + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const buArray = [];
	    	for(const bu of resp.data.data) {
	    		buArray.push(new BulkUpload(bu));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: buArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productBulkUploadDownloadErrors(getParams, uploadUuid, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_GET_BULK_UPLOAD_DOWNLOAD_ERRORS, { uuid: uploadUuid }) + getUrlParams(getParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal, 
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const resultsArray = [];
	    	for(const bu of resp.data.data) {
	    		resultsArray.push(new BulkUploadResult(bu));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: resultsArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchForeignVariants(productPermalink, plPermalink, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_GET_FOREIGN_VARIANTS, { 
			permalink: productPermalink, 
			pl_permalink: plPermalink,
		});
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {

	    	const foreignModelCode = resp.data.foreign_model || '';
				const modelClass = Product.getForeignModel(foreignModelCode);

				const variantResp = [];
				if(modelClass) {
					for(const vt of resp.data.variants) {
						variantResp.push(new modelClass(vt));
					}
				}

	    	return Promise.resolve(variantResp);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchSingle(productPermalink, plPermalink, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_GET_SINGLE, { 
			permalink: productPermalink,
			pl_permalink: plPermalink,
		});
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Product(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchInventorySingle(getData, plPermalink, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_INVENTORY_FETCH, getData);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Inventory(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchSingleExternal(productPermalink, plPermalink, isBuylist = false, signal = null) {
	return async (dispatch, getState) => {

		const urlParams = {
			permalink: productPermalink, 
			pl_permalink: plPermalink,
		}

		const queryParams = {};

		if(isBuylist === true) {
			queryParams[a.API_KEY_IS_BUYLIST] = 'true';
		}

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_SHOP_GET_SINGLE, urlParams) + getUrlParams(queryParams);
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new Product(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchSingleBySku(productSku, productPermalink, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_GET_SINGLE_BY_SKU, { sku: productSku });
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


// No refresh method; should always be called via other action
// I'll save you some confusion: this is AWS post code, not geographic post code
export function productGetMediaPostCode(productPermalink, plPermalink, filename, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_MEDIA_GENERATE, { 
			pl_permalink: plPermalink,
			permalink: productPermalink, 
			name: filename, 
		});
		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productUploadMedia(productPermalink, plPermalink, file, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const codeDispatch = await productGetMediaPostCode(productPermalink, plPermalink, generateMediaFilename(file), signal);
		const codeResp = await codeDispatch();
		if(!codeResp) {
			return Promise.reject();
		}

		const formData = new FormData();
    formData.append('Content-Type', file.type);
    formData.append('AWSAccessKeyId', codeResp.fields.AWSAccessKeyId);
    formData.append('acl', codeResp.fields.acl);
    formData.append('key', codeResp.fields.key);
    formData.append('policy', codeResp.fields.policy);
    formData.append('signature', codeResp.fields.signature);
    formData.append('file', file);

		return fetch(codeResp.url, {
		  method: 'post',
		  body: formData,
		  signal,
		})
		.then(parseStatus)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve({
	    		key: codeResp.fields.key,
	    	});
	    } else {
	    	return Promise.reject({
	    		error: ERROR_PRODUCT_IMAGE_UPLOAD,
	    	});
	    }
	  });
	}
}


export function productUploadBulkUploadCSV(codeResp, file, signal = null) {
	return async (dispatch, getState) => {

		const formData = new FormData();
    formData.append('Content-Type', file.type);
    formData.append('AWSAccessKeyId', codeResp.fields.AWSAccessKeyId);
    // formData.append('acl', codeResp.fields.acl);
    formData.append('key', codeResp.fields.key);
    formData.append('policy', codeResp.fields.policy);
    formData.append('signature', codeResp.fields.signature);
    formData.append('file', file);

		return fetch(codeResp.url, {
		  method: 'post',
		  body: formData,
		  signal,
		})
		.then(parseStatus)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve();
	    } else {
	    	return Promise.reject({
	    		error: ERROR_PRODUCT_IMAGE_UPLOAD,
	    	});
	    }
	  });
	}
}


export function productMediaAdd(mediaData, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_MEDIA_UPSERT, { 
			pl_permalink: mediaData.pl_permalink,
			permalink: mediaData.product_permalink, 
		});

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(mediaData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productMediaUpdate(productPermalink, plPermalink, mediaData, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_MEDIA_UPSERT, { 
			pl_permalink: plPermalink,
			permalink: productPermalink, 
		});

		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(mediaData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productMagicSearchProducts(getParams, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_MAGIC_NAME_SEARCH + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const magicCardArray = [];
	    	for(const card of resp.data.data) {
	    		magicCardArray.push(new MagicCard(card));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: magicCardArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productPokemonSearchProducts(getParams, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_POKEMON_NAME_SEARCH + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const pokemonCardArray = [];
	    	for(const card of resp.data.data) {
	    		pokemonCardArray.push(new PokemonCard(card));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: pokemonCardArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productLorcanaSearchProducts(getParams, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_LORCANA_NAME_SEARCH + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const lorcanaCardArray = [];
	    	for(const card of resp.data.data) {
	    		lorcanaCardArray.push(new LorcanaCard(card));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: lorcanaCardArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productStarwarsSearchProducts(getParams, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_STARWARS_NAME_SEARCH + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const starwarsCardArray = [];
	    	for(const card of resp.data.data) {
	    		starwarsCardArray.push(new StarwarsCard(card));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: starwarsCardArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productMagicSets(signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_MAGIC_SETS;

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const magicSetArray = [];
	    	for(const magicSet of resp.data.data) {
	    		magicSetArray.push(new MagicSet(magicSet));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: magicSetArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productPokemonSets(signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_POKEMON_SETS;

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const pokemonSetArray = [];
	    	for(const pokemonSet of resp.data.data) {
	    		pokemonSetArray.push(new PokemonSet(pokemonSet));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: pokemonSetArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productLorcanaSets(signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_LORCANA_SETS;

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const lorcanaSetArray = [];
	    	for(const lorcanaSet of resp.data.data) {
	    		lorcanaSetArray.push(new LorcanaSet(lorcanaSet));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: lorcanaSetArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productStarwarsSets(signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_STARWARS_SETS;

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const starwarsSetArray = [];
	    	for(const starwarsSet of resp.data.data) {
	    		starwarsSetArray.push(new StarwarsSet(starwarsSet));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: starwarsSetArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productMagicNameAutocomplete(searchTerm, language = null, signal = null) {
	return async (dispatch, getState) => {

		const getParams = {
			name: encodeURIComponent(searchTerm),
		};

		if(language && language.code) {
			getParams.language = language.code;
		}

		const url = a.API_TITAN_API + n.ENDPOINT_MAGIC_NAME_AUTOCOMPLETE + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productPokemonNameAutocomplete(searchTerm, language = null, signal = null) {
	return async (dispatch, getState) => {

		const getParams = {
			name: encodeURIComponent(searchTerm),
		};

		if(language && language.code) {
			getParams.language = language.code;
		}

		const url = a.API_TITAN_API + n.ENDPOINT_POKEMON_NAME_AUTOCOMPLETE + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productLorcanaNameAutocomplete(searchTerm, language = null, signal = null) {
	return async (dispatch, getState) => {

		const getParams = {
			name: encodeURIComponent(searchTerm),
		};

		if(language && language.code) {
			getParams.language = language.code;
		}

		const url = a.API_TITAN_API + n.ENDPOINT_LORCANA_NAME_AUTOCOMPLETE + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productStarwarsNameAutocomplete(searchTerm, language = null, signal = null) {
	return async (dispatch, getState) => {

		const getParams = {
			name: encodeURIComponent(searchTerm),
		};

		if(language && language.code) {
			getParams.language = language.code;
		}

		const url = a.API_TITAN_API + n.ENDPOINT_STARWARS_NAME_AUTOCOMPLETE + getUrlParams(getParams);

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productSearchInventory(searchObj, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_SHOP_SEARCH_INVENTORY;

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(searchObj),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const productsArray = [];
	    	for(const prod of resp.data.data) {
	    		productsArray.push(new Product(prod));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: productsArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productFetchBySkus(skuArray, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_SHOP_FETCH_BY_SKUS + getUrlParams({ skus: skuArray.join(',') });

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const productsArray = [];
	    	for(const prod of resp.data.data) {
	    		productsArray.push(new Product(prod));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: productsArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productSellFeaturedBuylist(getParams, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_SELL_FEATURED_BUYLIST + getUrlParams(getParams);;

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	
	    	const modelsResp = {};
	    	const defaultData = resp.data[a.API_RESP_KEY_DEFAULT];
	    	const aggregatedData = resp.data[a.API_RESP_KEY_AGGREGATIONS];

	    	if(defaultData) {
	    		const productsArray = [];
		    	for(const prod of defaultData.data) {
		    		productsArray.push(new Product(prod));
		    	}
		    	modelsResp[a.API_RESP_KEY_DEFAULT] = Object.assign({}, defaultData, { data: productsArray });
	    	}

	    	if(aggregatedData) {

	    		const aggregatedDataResp = {};

	    		for(const key in aggregatedData) {
	    			const productsArray = [];
			    	for(const prod of aggregatedData[key].data) {
			    		productsArray.push(new Product(prod));
			    	}
			    	aggregatedDataResp[key] = Object.assign({}, aggregatedData[key], { data: productsArray });
	    		}
	    		modelsResp[a.API_RESP_KEY_AGGREGATIONS] = aggregatedDataResp;
	    	}

	    	return Promise.resolve(modelsResp);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productInitiateAsyncBulkImport(bulkImportData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + n.ENDPOINT_PRODUCT_ADMIN_INIT_ASYNC_BULK_UPDATE;

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(bulkImportData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(Object.assign({}, resp.data, { product_upload: new BulkUpload(resp.data.product_upload) }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productTagCreate(plPermalink, productPermalink, tagData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_TAG_CREATE, {
			productLinePermalink: plPermalink,
			productPermalink: productPermalink,
		});

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(tagData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new ProductTag(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productTagUpdate(plPermalink, productPermalink, tagData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_TAG_UPDATE, {
			productLinePermalink: plPermalink,
			productPermalink: productPermalink,
			tagUuid: tagData.tag_uuid,
		});

		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(tagData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productTagDelete(plPermalink, productPermalink, tagData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_TAG_DELETE, {
			productLinePermalink: plPermalink,
			productPermalink: productPermalink,
			tagUuid: tagData.tag_uuid,
		});

		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(tagData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productAttributeCreate(plPermalink, productPermalink, attributeData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_ATTRIBUTE_CREATE, {
			productLinePermalink: plPermalink,
			productPermalink: productPermalink,			
		});

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(attributeData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(new ProductAttribute(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productAttributeUpdate(plPermalink, productPermalink, attributeData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_ATTRIBUTE_UPDATE, {
			productLinePermalink: plPermalink,
			productPermalink: productPermalink,
			attributeUuid: attributeData.attribute_uuid,
		});

		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(attributeData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productAttributeDelete(plPermalink, productPermalink, attributeData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_ATTRIBUTE_DELETE, {
			productLinePermalink: plPermalink,
			productPermalink: productPermalink,
			attributeUuid: attributeData.attribute_uuid,
		});

		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(attributeData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productSetsByProductLine(productLine, signal = null) {
	return async (dispatch, getState) => {

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_SHOP_FETCH_SETS, {
			productLinePermalink: productLine.permalink,
		});

		return fetch(url, {
		  method: 'get',
		  headers: authReq(a.API_HEADERS),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const productSetArray = [];
	    	const setModel = productLine.setModel;
	    	for(const productSet of resp.data.data) {
	    		productSetArray.push(new setModel(productSet));
	    	}
	    	return Promise.resolve(Object.assign({}, resp.data, { data: productSetArray }));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productSetCreate(productLine, setData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_SET_CREATE, {
			productLinePermalink: productLine.permalink,
		});

		return fetch(url, {
		  method: 'post',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(setData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	const setModel = productLine.setModel;
	    	return Promise.resolve(new setModel(resp.data));
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productSetUpdate(productLine, setData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_SET_UPDATE, {
			productLinePermalink: productLine.permalink,
			setUuid: setData.set_uuid,
		});

		return fetch(url, {
		  method: 'put',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(setData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}


export function productSetDelete(productLine, setData, signal = null) {
	return async (dispatch, getState) => {

		const refreshMethod = await authTokenRefresh(dispatch, getState);
		await refreshMethod();

		const url = a.API_TITAN_API + stringFormat(n.ENDPOINT_PRODUCT_ADMIN_SET_DELETE, {
			productLinePermalink: productLine.permalink,
			setUuid: setData.set_uuid,
		});

		return fetch(url, {
		  method: 'delete',
		  headers: authReq(a.API_HEADERS),
		  body: JSON.stringify(setData),
		  signal,
		})
		.then(parseJSON)
		.then((resp) => {
	    if(isOk(resp.status)) {
	    	return Promise.resolve(resp.data);
	    } else {
	    	return Promise.reject(resp.data);
	    }
	  });
	}
}



