import React from 'react';

export const BlockComponent = (props) => {
  
  // Props
  const {
    block,
  } = props;

  // State
  // const [editing, setEditing] = useState(false);

  // Accessing Redux state
  // const value = useSelector(state => state.someValue);

  // Internationalization
  // const { t } = useTranslation();

  // Methods
  const getComponent = () => {
    const DynamicBlock = block ? block.component : null;
    return DynamicBlock ? <DynamicBlock block={block} /> : null;
  }

  // Effects
  // None yet

  return (
    <div className={'BlockComponent'}>
      <div className='blockLiner'>{getComponent()}</div>
    </div>
  );
};

export default BlockComponent;




