import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TX_DURATION, 
  TX_LOADING, 
} from '../../../../../constants/strings';

import Dropdown from '../../../../Input/Dropdown';

export const DashboardGraphDateRange = (props) => {
  
  // Props
  const {
    dataLoading, 
    dataModel,
    duration,
    setDuration, 
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const getOptions = () => {
    if(dataLoading || !dataModel) {
      return [
        {
          display: TX_LOADING,
          value: '',
        }
      ];
    }
    return dataModel.getDurationOptions();
  };

  const handleChange = (evt) => {
    setDuration(evt.target.value);
  };

  return (
    <div className='DashboardGraphDateRange'>
      <div className='dateRangeWrapper'>
        <Dropdown
          className='dateDropdown'
          disabled={dataLoading || !dataModel}
          options={getOptions()}
          name={t(TX_DURATION)}
          value={duration}
          noTranslate={false}
          onChange={handleChange} />
      </div>
    </div>
  );
};

export default DashboardGraphDateRange;

