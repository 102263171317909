import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  FV_KEY_DISPLAY_MIN,
  FV_KEY_IS_SEALED,
  FV_KEY_PRODUCT_LINE,
} from '../../../../constants/filters';
import * as tx from '../../../../constants/strings';
import { URL_BUY_PRODUCT_LINE } from '../../../../constants/urls';

import { stringFormat } from '../../../../utils/formatting';

import BlockElementHorizontalProduct from '../BlockElementHorizontalProduct';
import { LoadingIcon } from '../../../Icons/LoadingIcon';

import * as productActionCreators from '../../../../actions/product';
import * as productLineActionCreators from '../../../../actions/product-line';
const allActionCreators = Object.assign({}, productActionCreators, productLineActionCreators);

export class BlockMagicSinglesVertical extends Component {

  constructor(props) {
    super(props);

    this.REQUEST_COUNT = 8;

    this.state = {
      loading: true,

      targetProducts: this.REQUEST_COUNT,

      productData: null,
      productLine: null,
    };

    this.bodyElement = React.createRef();
    this.scrollElement = React.createRef();

    // Controllers
    this.controller = null;

    this.heightInterval = null;

    this.checkProductCount = this.checkProductCount.bind(this);
  }

  componentDidMount() {
    if(this.props.productLine.enabled && this.props.productLine.enabled.length) {
      const pl = this.getProductLine();
      if(pl) {
        this.setState({ productLine: pl }, () => {
          this.fetchProducts();
        });
      }
    } else {
      this.props.productLineFetchEnabled()
      .catch((errResp) => {
        if(errResp) { console.error(errResp); }
      });
    }

    // Start height interval
    this.heightInterval = setInterval(this.checkProductCount, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    
    this.checkProductCount();

    // If enabled product lines saved from elsewhere, fire request when saved
    if(!prevProps.productLine.enabled && this.props.productLine.enabled && this.props.productLine.enabled.length) {
      const pl = this.getProductLine();
      if(pl) {
        this.setState({ productLine: pl }, () => {
          this.fetchProducts();
        });
      }
    }
  }

  componentWillUnmount() {
    if(this.heightInterval) {
      clearInterval(this.heightInterval);
    }
  }

  checkProductCount() {
    if(this.bodyElement && this.bodyElement.current && this.scrollElement && this.scrollElement.current) {
      if(this.scrollElement.current.getBoundingClientRect().height > this.bodyElement.current.getBoundingClientRect().height) {
        this.setState({ targetProducts: this.state.targetProducts - 1 });
      }
    }
  }

  getProductLine() {
    for(const pl of this.props.productLine.enabled) {
      if(pl.permalink === this.props.block.requiredProductLine) {
        return pl;
      }
    }
    return null;
  }

  async fetchProducts() {

    if(!this.state.productLine) { return null; }
    
    // Cancel any existing requests
    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({ 
      loading: true, 
    });

    const requestFilters = {
      [FV_KEY_IS_SEALED]: false,
      [FV_KEY_PRODUCT_LINE]: [ this.state.productLine.permalink ],
      [FV_KEY_DISPLAY_MIN]: 'True',
    };

    const paginationData = {
      limit: this.REQUEST_COUNT,
      offset: 0,
    }

    const reqFilterObject = Object.assign({}, requestFilters, paginationData);
    const productResp = await this.props.productSearchInventory(reqFilterObject, controller.signal)
      .catch((errResp) => {
        this.setState({
          productData: [],
          loading: false, 
        });
      });

    if(!productResp) {
      return null;
    }

    this.setState({
      productData: productResp.data,
      loading: false, 
    });
  }

  render() {

    let productsRendered = 0;
    const {t} = this.props;

    return <div className={'BlockMagicSinglesVertical BlockTypeProductsVertical'}>
      <div className='btpvLiner'>
        <div className='horizontalRowTitle'>
          <div className='FlexCenter'>{this.props.block.isDefaultConfig('title') ? t(this.props.block.getConfigAttr('title')) : this.props.block.getConfigAttr('title')}</div>
        </div>

        <div className='horizontalRowBody' ref={this.bodyElement}>
          {this.state.loading ?
            <div className='horiontalRowBodyIcon'>
              <LoadingIcon />
            </div> :
            <div className='horizontalRowBodyLiner' ref={this.scrollElement}>
              {this.state.productData && this.state.productData.length > 0 ?
                <>
                  {this.state.productData.map((prod, i) => {

                    if(productsRendered >= this.state.targetProducts) {
                      return null;
                    }
                    productsRendered++;

                    return <div key={i} className='elementWrapper'>
                      <BlockElementHorizontalProduct
                        isBuylist={false}
                        product={prod} />
                    </div>
                  })}
                </> :
                <div className='noResults'>
                  {t(tx.TX_GALLERY_NO_RESULTS)}
                </div>
              }
            </div>
          }
        </div>

        <div className='horizontalRowActionFooter'>
          <Link to={stringFormat(URL_BUY_PRODUCT_LINE, { productLine: this.props.block.requiredProductLine })} className='horizontalRowAction'>{this.state.productLine ? t(tx.TX_SHOP_NOUN, { noun: this.state.productLine.name }) : t(tx.TX_SHOP_NOW)}</Link>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockMagicSinglesVertical));