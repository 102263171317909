import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FV_KEY_MAGIC_SET } from '../../../constants/filters';
import { URL_BUY_PRODUCT_LINE } from '../../../constants/urls';
import { PL_PERMALINK_MAGIC } from '../../../constants/product';

import { stringFormat } from '../../../utils/formatting';

import '../style/_homebanner.scss';

import * as productActionCreators from '../../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class HomeBanner extends Component {

  setNameSearch(val) {
    this.props.productSetNameValue(val);
  }

  render() {
    return <div className='HomeBanner'>
      <Link
        to={{
          pathname: stringFormat(URL_BUY_PRODUCT_LINE, { productLine: PL_PERMALINK_MAGIC }),
          state: {
            filters: {
              [FV_KEY_MAGIC_SET]: ['blb', 'blc'],
            }
          }
        }} 
        className='bannerLinkWrapper'>
                      
        <div className='homeBannerWrapper'>
          <img 
            className='bannerImage bannerDesktopImage'
            src={'/store/' + process.env.REACT_APP_STORE_CODE + '/home/banner.jpg'}
            alt={process.env.REACT_APP_STORE_CODE} />
          <img 
            className='bannerImage bannerMobileImage'
            src={'/store/' + process.env.REACT_APP_STORE_CODE + '/home/banner-mobile.jpg'}
            alt={process.env.REACT_APP_STORE_CODE} />
        </div>
      </Link>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(mapStateToProps, allActionCreators)(HomeBanner);