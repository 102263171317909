import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import {
  API_KEY_DISPLAY_MIN,
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,  
} from '../../../../constants/api';
import { CSS_HOMEPAGE_MOBILE_BREAK } from '../../../../constants/css';
import * as tx from '../../../../constants/strings';

import BlockElementVerticalProduct from '../BlockElementVerticalProduct';
import { LoadingIcon } from '../../../Icons/LoadingIcon';

import * as productActionCreators from '../../../../actions/product';
const allActionCreators = Object.assign({}, productActionCreators);

export class BlockRecentlyAdded extends Component {

  constructor(props) {
    super(props);

    this.DISPLAY_COUNT_DESKTOP = 8;
    this.DISPLAY_COUNT_MOBILE = 4;

    this.DISPLAY_OFFSET_UNITS = 0;

    this.REQUEST_COUNT = (this.DISPLAY_OFFSET_UNITS + 1) * Math.max(this.DISPLAY_COUNT_DESKTOP, this.DISPLAY_COUNT_MOBILE);

    this.state = {
      loading: true,

      productData: null,

      displayCount: this.REQUEST_COUNT,
    };    

    this.productWrapper = React.createRef();

    // Controllers
    this.controller = null;

    this.checkRowsThrottled = _.throttle(this.checkRows.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkRowsThrottled, false);
    this.checkRows();
    this.fetchRecentProducts();
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }

    window.removeEventListener('resize', this.checkRowsThrottled, false);
  }

  checkRows() {
    if(window.innerWidth <= CSS_HOMEPAGE_MOBILE_BREAK && this.state.displayCount !== this.DISPLAY_COUNT_MOBILE) {
      this.setState({ displayCount: this.DISPLAY_COUNT_MOBILE });
    } else if(window.innerWidth > CSS_HOMEPAGE_MOBILE_BREAK && this.state.displayCount !== this.DISPLAY_COUNT_DESKTOP) {
      this.setState({ displayCount: this.DISPLAY_COUNT_DESKTOP });
    }
  }

  async fetchRecentProducts() {
    
    // Cancel any existing requests
    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({ 
      loading: true, 
    });

    const paginationData = {
      [API_KEY_LIMIT]: this.REQUEST_COUNT,
      [API_KEY_OFFSET]: 0,
      [API_KEY_DISPLAY_MIN]: 'True',
      [API_KEY_SORT]: '-id',
    }

    const reqFilterObject = Object.assign({}, paginationData);
    const productResp = await this.props.productSearchInventory(reqFilterObject, controller.signal)
      .catch((errResp) => {
        this.setState({
          productData: [],
          loading: false, 
        });
      });

    if(!productResp) {
      return null;
    }

    this.setState({
      productData: productResp.data,
      loading: false, 
    }, () => {
      this.checkRows();
    });
  }

  getDisplayArray() {
    if(!this.state.productData || !this.state.productData.length) { return null; }
    return this.state.productData.slice(-1 * this.state.displayCount);
  }

  render() {

    let productsRendered = 0;
    const {t} = this.props;

    return <div className={'BlockRecentlyAdded BlockTypeProductsHorizontal'}>
      <div className='braLiner'>
        <div className='blockTitle'>{this.props.block.isDefaultConfig('title') ? t(this.props.block.getConfigAttr('title')) : this.props.block.getConfigAttr('title')}</div>
        
        <div className='blockBody'>
          {this.state.loading ?
            <div className='blockBodyLoadingIcon'>
              <LoadingIcon />
            </div> :
            <div className='blockBodyLiner'>
              {this.state.productData && this.state.productData.length > 0 ?
                <div ref={this.productWrapper}>
                  {this.getDisplayArray().map((prod, i) => {

                    if(productsRendered >= this.state.displayCount) {
                      return null;
                    }
                    productsRendered++;

                    return <div key={i} className='elementWrapper'>
                      <BlockElementVerticalProduct
                        product={prod} />
                    </div>
                  })}
                </div> :
                <div className='noResults'>
                  {t(tx.TX_GALLERY_NO_RESULTS)}
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockRecentlyAdded));