import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  ERR_5500,
  ERROR_ORDER_CARRIER_MISSING,
  ERROR_ORDER_CARRIER_SELECT,
  ERROR_ORDER_TRACKING_NUMBER_MISSING, 
} from '../../../../constants/errors';
import { ORDER_STATUS_COMPLETE } from '../../../../constants/orders';
import { SHIPMENT_CARRIER_KEY_OTHER } from '../../../../constants/shipments';
import * as tx from '../../../../constants/strings';

import { 
  Shipment,
  ShipmentCarrier, 
} from '../../../../models/shipments';

import { 
  getNotEmptyError, 
  isFormValid, 
} from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';

import Dropdown from '../../../Input/Dropdown';
import Toggle from '../../../Input/Toggle';

import '../../style/_vieworder.scss';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class OrderModalShipmentCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {

      inputCarrier: '',
      inputCarrierAltName: '', 
      inputHasTracking: false,
      inputTracking: '',
      
      errorCarrier: '',
      errorCarrierAltName: '',
      errorTracking: '',

      requestPending: false,
      requestError: null,
    }

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.confirmSignal && prevProps.confirmSignal !== this.props.confirmSignal) {
      this.saveAction();
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  static confirmLabel() {
    return tx.TX_CREATE;
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  validateAll() {

    const errorObj = {
      errorCarrier: getNotEmptyError(this.state.inputCarrier, this.getCarrierError()),
    };

    if(this.state.inputCarrier && this.state.inputCarrier.key === SHIPMENT_CARRIER_KEY_OTHER) {
      errorObj['errorCarrierAltName'] = getNotEmptyError(this.state.inputCarrierAltName, ERROR_ORDER_CARRIER_MISSING);
    } else {
      errorObj['errorCarrierAltName'] = '';
    }

    if(this.state.inputCarrier && this.state.inputCarrier.hasTracking && this.state.inputHasTracking) {
      errorObj['errorTracking'] = getNotEmptyError(this.state.inputTracking, ERROR_ORDER_TRACKING_NUMBER_MISSING);
    } else {
      errorObj['errorTracking'] = '';
    }

    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  async saveAction(evt) {

    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      // Create shipment
      const newShipment = new Shipment({ 
        trackingNumber: this.state.inputTracking,
        carrier: this.state.inputCarrier,
        carrierAlt: this.state.inputCarrierAltName,
      });

      const shipmentResp = await this.props.ordersAddShipment(newShipment.getApiData(), this.props.order.publicUuid)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: errResp && errResp.error ? errResp.error : ERR_5500,
          });
          this.props.completeAction();
        });

      if(!shipmentResp) {
        this.props.completeAction();
        return null;
      }

      const statusData = {
        status: ORDER_STATUS_COMPLETE.key,
        reason: this.props.t(tx.TX_ORDER_HISTORY_SHIPMENT),
      };

      const statusResp = await this.props.ordersUpdateStatus(statusData, this.props.order.publicUuid)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });
          this.props.completeAction();
        });

      if(!statusResp) {
        this.props.completeAction();
        return null;
      }

      this.props.makeClean();
      this.props.closeMethod();
      this.props.completeAction();
      
    } else {
      this.props.completeAction();
    }
  }

  changeCarrier(evt) {
    this.setState({
      inputCarrier: new ShipmentCarrier(evt.target.value),
    }, () => {
      if(this.state.errorCarrier) {
        this.validateCarrier();
      }
      if(evt.target.value === '' && this.state.inputHasTracking) {
        this.setState({
          inputHasTracking: false,
        });
      }
    });
  }

  validateCarrier() {
    this.setState({ errorCarrier: getNotEmptyError(this.state.inputCarrier, this.getCarrierError()) });
  }

  getCarrierOptions() {
    const respArray = [];
    try {
      respArray.push({
        display: tx.TX_PLACEHOLDER_SHIPMENT_CARRIER,
        value: '',
      });
      for(const carrierObj of ShipmentCarrier.getByCounty(this.props.user && this.props.user.store ? this.props.user.store.countryCode : null)) {
        
        const shippingCarrier = new ShipmentCarrier(carrierObj);
        if(shippingCarrier.key === SHIPMENT_CARRIER_KEY_OTHER) {
          continue;
        }

        respArray.push({
          display: shippingCarrier.name,
          value: shippingCarrier.key,
        });
      }
      respArray.push({
        display: tx.TX_OTHER,
        value: SHIPMENT_CARRIER_KEY_OTHER,
      });
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  getCarrierError() {
    return this.state.inputCarrier && this.state.inputCarrier.key === SHIPMENT_CARRIER_KEY_OTHER ? ERROR_ORDER_CARRIER_MISSING : ERROR_ORDER_CARRIER_SELECT;
  }

  changeCarrierAltName(evt) {
    this.setState({
      inputCarrierAltName: evt.target.value,
    }, () => {
      if(this.state.errorCarrierAltName) {
        this.validateCarrierAltName();
      }
    });
  }

  validateCarrierAltName() {
    this.setState({ errorCarrierAltName: getNotEmptyError(this.state.inputCarrierAltName, ERROR_ORDER_CARRIER_MISSING) });
  }

  changeHasTracking(evt) {
    this.setState({
      inputHasTracking: !this.state.inputHasTracking,
    });
  }

  changeTracking(evt) {
    this.setState({
      inputTracking: evt.target.value,
    }, () => {
      if(this.state.errorTracking) {
        this.validateTracking();
      }
    });
  }

  validateTracking() {
    this.setState({ errorTracking: getNotEmptyError(this.state.inputTracking, ERROR_ORDER_TRACKING_NUMBER_MISSING) });
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderModalShipmentCreate OrderModalView'}>
      <div className='omvWrapper'>
        
        <form 
          className={'shipmentCreateForm orderModalForm'}
          onSubmit={this.saveAction.bind(this)}>

          <div className={this.state.requestError ? 'omFormError present' : 'omFormError'}>{t(this.state.requestError)}</div>

          {this.props.order && this.props.order.shippingMethod && this.props.order.shippingMethod.integration && this.props.order.shippingMethod.integration.createShipmentComponent ?
            <div className={'orderModalCopy centerCopy'}>
              <div className='copyAction' onClick={this.props.toggleShippingDefault.bind(this)}>{t(tx.TX_ORDER_SHIPMENT_PROMPT_DEFAULT_ALTERNATE, { name: this.props.order.shippingMethod.integration.provider.name })}</div>
            </div> :
            null
          }

          <div className='omFieldWrapper'>
            <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_SHIPMENT_CARRIER)}</div>
            <div className='omInputWrapper'>
              <div className='omDropdownWrapper'>
                <Dropdown 
                  className={'omDropdownSelect'}
                  options={this.getCarrierOptions()}
                  name={t(tx.TX_ORDER_SHIPMENT_CARRIER)}
                  value={this.state.inputCarrier && this.state.inputCarrier.key ? this.state.inputCarrier.key : ''}
                  required={true}
                  onChange={this.changeCarrier.bind(this)} />
              </div>
              {this.state.errorCarrier ?
                <div className={'FieldError'}>{t(this.state.errorCarrier)}</div> :
                null
              }
            </div>
          </div>

          {this.state.inputCarrier && this.state.inputCarrier.key === SHIPMENT_CARRIER_KEY_OTHER ?
            <div className='omFieldWrapper'>
              <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_SHIPMENT_CARRIER_NAME)}</div>
              <div className='omInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorCarrierAltName ? 'InputError' : ''}
                  value={this.state.inputCarrierAltName}
                  onChange={this.changeCarrierAltName.bind(this)}
                  onBlur={this.validateCarrierAltName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_CARRIER_NAME)}
                  maxLength={100} />
              </div>
              {this.state.errorCarrierAltName ?
                <div className={'FieldError'}>{t(this.state.errorCarrierAltName)}</div> :
                null
              }
            </div> :
            null
          }

          {this.state.inputCarrier && this.state.inputCarrier.hasTracking ?
            <div className='omFieldWrapper'>
              <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_HAS_TRACKING_QUESTION)}</div>
              <div className='omInputWrapper'>
                <div className='omInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputHasTracking}
                    onToggle={this.changeHasTracking.bind(this)}
                    trueValue={tx.TX_YES}
                    falseValue={tx.TX_NO} />
                </div>
              </div>
            </div> :
            null
          }

          {this.state.inputCarrier && this.state.inputCarrier.hasTracking && this.state.inputHasTracking ?
            <div className='omFieldWrapper'>
              <div className={'omFieldLabel omRequired'}>{t(tx.TX_ORDER_SHIPMENT_TRACKING_NUMBER)}</div>
              <div className='omInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorTracking ? 'InputError' : ''}
                  value={this.state.inputTracking}
                  onChange={this.changeTracking.bind(this)}
                  onBlur={this.validateTracking.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_TRACKING_NUMBER)}
                  maxLength={100} />
              </div>
              {this.state.errorTracking ?
                <div className={'FieldError'}>{t(this.state.errorTracking)}</div> :
                null
              }
            </div> :
            null
          }

        </form>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderModalShipmentCreate));