import React from 'react';

import LoadingIcon from '../Icons/LoadingIcon';
import SectionComponent from './SectionComponent';

import './style/_layout.scss';

export const LayoutComponent = (props) => {
  
  // Props
  const {
    adminTheme,
    layout,
    layoutLoading,
  } = props;

  // State
  // None yet

  // Methods
  // None yet

  // Effects
  // None yet

  return (
    <div className={`LayoutComponent ${adminTheme ? 'titanTheme' : ''}`}>
      <div className='layoutLiner'>
        {!layout || layoutLoading ?
          <div className='layoutLoading'>
            <div className='loadingIconWrapper'>
              <LoadingIcon />
            </div>
          </div> :
          <>
            {layout.sections.map((section, i) => {
              return <div key={i} className='layoutComponentWrapper'>
                <SectionComponent
                  adminTheme={adminTheme}
                  section={section} />
              </div>;
            })}
          </>
        }
      </div>
    </div>
  );
};

export default LayoutComponent;




