

import { API_FOREIGN_MODEL_MAGIC_CARD } from './api';
import { PL_PERMALINK_MAGIC } from './product';

// Using the store codes here causes a circular import...
// Haven't nailed down why, but these should sent from the backend
// And we shouldn't be hard coding exceptions
// Ddefault layouts have been defined on the backend, but not ones custom to the store
// Specifically, the problem comes from LAYOUT_STORE_DEFAULT_OVERRIDES, which is only used on homepage, not dashboard
// Once this can be safely removed, we can delete it and this will most likely not be an issue
// import {
// 	STORE_CODE_FLYINGBEAR,
// 	STORE_CODE_OASISCHILE,
// } from './store';

import {
	TX_BLOCK_FEATURED_BUYLIST_TITLE,
	TX_BLOCK_MAGIC_SEALED_TITLE,
	TX_BLOCK_MAGIC_SINGLES_TITLE,
	TX_BLOCK_MAGIC_TOP_EDH_TITLE,
	TX_BLOCK_RECENTLY_ADDED_TITLE,
	TX_BLOCK_UPCOMING_EVENTS_TITLE,
	TX_HOME,
	TX_LAYOUT_HOME_DESCRIPTION,
	TX_LAYOUT_HOME_LABEL,
	TX_LAYOUT_HOME_NAME,
} from './strings';






// Block

export const BLOCK_TYPE_DASHBOARD_SALES_GRAPH = 'dashboard-sales-graph';
export const BLOCK_TYPE_DASHBOARD_TOP_LIST = 'dashboard-top-list';
export const BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL = 'featured-buylist-horizontal';
export const BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL = 'featured-buylist-vertical';
export const BLOCK_TYPE_MAGIC_SEALED_VERTICAL = 'magic-sealed-vertical';
export const BLOCK_TYPE_MAGIC_SINGLES_VERTICAL = 'magic-singles-vertical';
export const BLOCK_TYPE_MAGIC_TOP_EDH = 'magic-top-edh';
export const BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL = 'recently-added-horizontal';
export const BLOCK_TYPE_UPCOMING_EVENTS = 'upcoming-events';


// Block defaults
export const BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_HORIZONTAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_VERTICAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_MAGIC_SEALED_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SEALED_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SINGLES_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_MAGIC_TOP_EDH = {
	type: BLOCK_TYPE_MAGIC_TOP_EDH,
	config: {},
};

export const BLOCK_DEFAULT_HOME_RECENTLY_ADDED = {
	type: BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_UPCOMING_EVENTS = {
	type: BLOCK_TYPE_UPCOMING_EVENTS,
	config: {},
};


// Block configs
export const BLOCK_CONFIG_ANALYTICS_SALES_GRAPH = {
	type: BLOCK_TYPE_DASHBOARD_SALES_GRAPH,
	defaultConfig: {},
};

export const BLOCK_CONFIG_FEATURED_BUYLIST_HORIZONTAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL,
	defaultConfig: {
		title: TX_BLOCK_FEATURED_BUYLIST_TITLE,
	},
};

export const BLOCK_CONFIG_FEATURED_BUYLIST_VERTICAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL,
	defaultConfig: {
		title: TX_BLOCK_FEATURED_BUYLIST_TITLE,	

		// Oasis-specific defaults
		product_line_permalink: PL_PERMALINK_MAGIC,
		foreign_model: API_FOREIGN_MODEL_MAGIC_CARD,
		aggregation_count: 2,
		aggregation_key: 'magic_formats',
		aggregation_value: 'standard,modern',
		buy_price_min: 5,
		omit_tags: true,
		total_qty_max: 0,
	},
};

export const BLOCK_CONFIG_MAGIC_SEALED_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SEALED_VERTICAL,
	requiredProductLine: PL_PERMALINK_MAGIC,
	defaultConfig: {
		title: TX_BLOCK_MAGIC_SEALED_TITLE,
	},
};

export const BLOCK_CONFIG_MAGIC_SINGLES_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SINGLES_VERTICAL,
	requiredProductLine: PL_PERMALINK_MAGIC,
	defaultConfig: {
		title: TX_BLOCK_MAGIC_SINGLES_TITLE,
	},
};

export const BLOCK_CONFIG_MAGIC_TOP_EDH = {
	type: BLOCK_TYPE_MAGIC_TOP_EDH,
	requiredProductLine: PL_PERMALINK_MAGIC,
	defaultConfig: {
		title: TX_BLOCK_MAGIC_TOP_EDH_TITLE,
	},
};

export const BLOCK_CONFIG_RECENTLY_ADDED = {
	type: BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL,
	defaultConfig: {
		title: TX_BLOCK_RECENTLY_ADDED_TITLE,
	},
};

export const BLOCK_CONFIG_UPCOMING_EVENTS = {
	type: BLOCK_TYPE_UPCOMING_EVENTS,
	defaultConfig: {
		title: TX_BLOCK_UPCOMING_EVENTS_TITLE,
	},
};


export const BLOCK_CONFIG_ALL = [
	BLOCK_CONFIG_ANALYTICS_SALES_GRAPH,
	BLOCK_CONFIG_FEATURED_BUYLIST_HORIZONTAL,
	BLOCK_CONFIG_FEATURED_BUYLIST_VERTICAL,
	BLOCK_CONFIG_MAGIC_SEALED_VERTICAL,
	BLOCK_CONFIG_MAGIC_SINGLES_VERTICAL,
	BLOCK_CONFIG_MAGIC_TOP_EDH,
	BLOCK_CONFIG_RECENTLY_ADDED,
	BLOCK_CONFIG_UPCOMING_EVENTS,
];




// Section

export const SECTION_TYPE_FULL_WIDTH_VERTICAL = 'full-width-vertical'; // Vertical component spanning full width
export const SECTION_TYPE_HORIZONTAL_BLOCKS = 'horizontal-blocks'; 

export const SECTION_ANIMATION_SLIDE_UP_BLOCKS = 'slide-up-blocks';

export const SECTION_DEFAULT_HOME_TOP = {
	type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
	blocks: [

	],
};

export const SECTION_DEFAULT_HOME_HORIZONTAL = {
	type: SECTION_TYPE_HORIZONTAL_BLOCKS,
	animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
	blocks: [
		BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL,
		BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_VERTICAL,
		BLOCK_DEFAULT_HOME_UPCOMING_EVENTS,
	],
};

export const SECTION_DEFAULT_HOME_BOTTOM = {
	type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
	blocks: [
		BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
	],
};

export const SECTION_CONFIG_FULL_VERTICAL = {
	type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
	allowedBlocks: [],
	blockCountMin: 0,
	blockCountMax: 16,
};

export const SECTION_CONFIG_HORIZONTAL_BLOCKS = {
	type: SECTION_TYPE_HORIZONTAL_BLOCKS,
	allowedBlocks: [],
	blockCountMin: 2,
	blockCountMax: 3,
};


// Layout

// TODO: to finish a pagebuilder there will need to be a LAYOUT TYPE 

export const LAYOUT_KEY_HOME = 'home';
export const LAYOUT_KEY_DASHBOARD = 'dashboard';

export const LAYOUT_TYPE_SIDEBAR_RIGHT = 'sidebar-right';
export const LAYOUT_TYPE_VERTICAL = 'vertical';

export const LAYOUT_TYPE_CONFIG_VERTICAL = {
	type: LAYOUT_TYPE_VERTICAL,
	allowedSections: [
		SECTION_CONFIG_FULL_VERTICAL, 
		SECTION_CONFIG_HORIZONTAL_BLOCKS, 
	],
};

// Layout defaults
export const LAYOUT_DEFAULT_HOME = {
	key: LAYOUT_KEY_HOME,
	name: TX_LAYOUT_HOME_NAME,
	label: TX_LAYOUT_HOME_LABEL,
	description: TX_LAYOUT_HOME_DESCRIPTION,
	type: LAYOUT_TYPE_VERTICAL,
	sections: [
		SECTION_DEFAULT_HOME_HORIZONTAL,
		SECTION_DEFAULT_HOME_BOTTOM,
	],
};

// Layout instances configs
export const LAYOUT_CONFIG_HOME = {
	key: LAYOUT_KEY_HOME,
	default: LAYOUT_DEFAULT_HOME,
	allowedTypes: [
		LAYOUT_TYPE_VERTICAL,
	],
};

export const LAYOUT_CONFIGS_ALL = [
	LAYOUT_CONFIG_HOME,
];

export const LAYOUT_STORE_DEFAULT_OVERRIDES = {
	flyingbear: {
		[LAYOUT_KEY_HOME]: {
			sections: [
				{
					type: SECTION_TYPE_HORIZONTAL_BLOCKS,
					animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
					blocks: [
						BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL,
						BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_VERTICAL,
						BLOCK_DEFAULT_HOME_UPCOMING_EVENTS,
					],
				},
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					blocks: [
						BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_HORIZONTAL,
						BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
					],
				},
			],
		},
	},
	oasischile: {
		[LAYOUT_KEY_HOME]: {
			sections: [
				{
					type: SECTION_TYPE_HORIZONTAL_BLOCKS,
					animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
					blocks: [
						BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL,
						BLOCK_DEFAULT_HOME_MAGIC_SEALED_VERTICAL,
						BLOCK_DEFAULT_HOME_UPCOMING_EVENTS,
					],
				},
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					blocks: [
						BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_HORIZONTAL,
						BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
					],
				},
			],
		},
	},
};



// Page

export const PAGE_KEY_HOME = 'home';

export const PAGE_HOME = {
	key: PAGE_KEY_HOME,
	name: TX_HOME,
};

export const PAGES_ALL = [
	PAGE_HOME,
];
