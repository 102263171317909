

export const API_TITAN_API = process.env.REACT_APP_BUILD_TITAN_API;

export const API_EXTERNAL_STAMPS_API = process.env.REACT_APP_BUILD_EXTERNAL_STAMPS_API;

export const API_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
};
export const API_FILE_HEADERS = {
	'Accept': 'application/json'
};

export const API_KEY_AGGREGATION_KEY = 'aggregation_key';
export const API_KEY_AGGREGATION_VALUES = 'aggregation_values';
export const API_KEY_BUY_PRICE_MIN = 'buy_price_min';
export const API_KEY_BUY_PRICE_MAX = 'buy_price_max';
export const API_KEY_CUSTOM_NAME = 'name_custom';
export const API_KEY_DISPLAY_MIN = 'display_min';
export const API_KEY_DURATION = 'duration';
export const API_KEY_EXPORT_TYPE = 'export_type';
export const API_KEY_FULL_EXPORT = 'full_export';
export const API_KEY_FOREIGN_ID = 'foreign_id';
export const API_KEY_FOREIGN_MODEL = 'foreign_model';
export const API_KEY_FOREIGN_SET_ID = 'foreign_set_id';
export const API_KEY_FOREIGN_SET_MODEL = 'foreign_set_model';
export const API_KEY_IS_BUYLIST = 'is_buylist';
export const API_KEY_IS_ENABLED = 'is_enabled';
export const API_KEY_LIMIT = 'limit';
export const API_KEY_OFFSET = 'offset';
export const API_KEY_OMIT_TAGS = 'omit_tags';
export const API_KEY_PRODUCT_LINE = 'product_line';
export const API_KEY_QUANTITY_MAX = 'quantity_max';
export const API_KEY_QUANTITY_MIN = 'quantity_min';
export const API_KEY_QUICK_SEARCH = 'quick';
export const API_KEY_SEARCH = 'search';
export const API_KEY_SELL_PRICE_MIN = 'price_min';
export const API_KEY_SELL_PRICE_MAX = 'price_max';
export const API_KEY_SORT = 'sort';
export const API_KEY_TOTAL_QTY_MIN = 'total_qty_min';
export const API_KEY_TOTAL_QTY_MAX = 'total_qty_max';

export const API_EXPORT_TYPE = 'csv';

export const API_FOREIGN_MODEL_LORCANA_CARD = 'lorcana_card';
export const API_FOREIGN_MODEL_LORCANA_SEALED = 'lorcana_sealed';
export const API_FOREIGN_MODEL_LORCANA_SET = 'lorcana_set';
export const API_FOREIGN_MODEL_MAGIC_CARD = 'magic_card';
export const API_FOREIGN_MODEL_MAGIC_SEALED = 'magic_sealed';
export const API_FOREIGN_MODEL_MAGIC_SET = 'magic_set';
export const API_FOREIGN_MODEL_POKEMON_CARD = 'pokemon_card';
export const API_FOREIGN_MODEL_POKEMON_SEALED = 'pokemon_sealed';
export const API_FOREIGN_MODEL_POKEMON_SET = 'pokemon_set';
export const API_FOREIGN_MODEL_STARWARS_CARD = 'starwars_card';
export const API_FOREIGN_MODEL_STARWARS_SEALED = 'starwars_sealed';
export const API_FOREIGN_MODEL_STARWARS_SET = 'starwars_set';

export const API_RESP_KEY_AGGREGATIONS = 'aggregations';
export const API_RESP_KEY_DEFAULT = 'default';


// 8 hours
export const AUTH_TOKEN_DURATION = 8 * 60 * 60 * 1000; 
export const REAUTH_TOKEN_DURATION = 4 * 60 * 60 * 1000;