import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../store';

import { FV_KEY_NAME } from '../../../constants/filters';
import { ICON_SEARCH } from '../../../constants/icons';
import * as tx from '../../../constants/strings';
import {
  URL_BUY_GENERAL, 
  URL_BUY_PRODUCT_LINE, 
  URL_SELL_GENERAL,
  URL_SELL_PRODUCT_LINE,
  URL_NS_SELL,
} from '../../../constants/urls';

import { amIHere } from '../../../utils/general';

import { Icon } from '../../Icons/Icon';

import '../style/_headersearch.scss';

import * as productActionCreators from '../../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class HeaderSearch extends Component {

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.searchAction();
    }
  }

  searchAction() {

    const searchValue = this.props.product.nameSearchValue ? this.props.product.nameSearchValue : '';
    const passedFilters = {
      [FV_KEY_NAME]: searchValue,
    }

    const isBuylist = window.location.pathname.startsWith(`/${URL_NS_SELL}`);

    const generalUrl = isBuylist ? URL_SELL_GENERAL : URL_BUY_GENERAL;
    const productLineUrl = isBuylist ? URL_SELL_PRODUCT_LINE : URL_BUY_PRODUCT_LINE;

    const skipNav = amIHere(generalUrl) || amIHere(productLineUrl);
    if(skipNav) {
      history.replace(window.location.pathname, {filters: passedFilters});
    } else {
      history.push(generalUrl, {filters: passedFilters});
    }
  }

  changeName(evt) {
    this.props.productSetNameValue(evt.target.value);
  }

  render() {

    const {t} = this.props;

    return <div className='HeaderSearch'>
      
      <div className='searchInnerWrapper'>
        <div className='searchInputWrapper'>
          <input 
            value={this.props.product.nameSearchValue}
            placeholder={t(tx.TX_PLACEHOLDER_SEARCH)}
            onChange={this.changeName.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)} />
        </div>
        <div className='searchButtonWrapper' onClick={this.searchAction.bind(this)}>
          <div className='searchButtonInnerWrapper'>{t(tx.TX_SEARCH)}</div>
          <div className='searchButtonInnerWrapperTablet'>
            <Icon 
              value={ICON_SEARCH} 
              iconClass='searchTabletIcon' />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    product: state.product
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(HeaderSearch));


