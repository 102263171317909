
import { 
	subMonths,
	subWeeks, 
} from 'date-fns';
import { toDate } from 'date-fns-tz';

import {
	ANALYTICS_CONFIG_METRIC,
	ANALYTICS_CONFIG_MODE,
	ANALYTICS_CONFIG_SHOW_TODAY,
	ANALYTICS_DURATION_UNIT_MONTH,
  	ANALYTICS_DURATION_UNIT_WEEK,
	ANALYTICS_METRIC_COUNT,
	ANALYTICS_METRIC_COUNT_ITEMS,
	ANALYTICS_METRIC_TOTAL,
	ANALYTICS_SALES_MODE_ORDERS, 
	ANALYTICS_SALES_MODE_PRODUCT_LINES, 
} from '../../constants/analytics';
import { PL_COLORS } from '../../constants/product';
import { 
	TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT, 
	TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT_ITEMS, 
	TX_ANALYTICS_CONFIG_METRIC_VALUE_TOTAL,
	TX_ANALYTICS_CONFIG_MODE_VALUE_ORDERS,
	TX_ANALYTICS_CONFIG_MODE_VALUE_PRODUCT_LINES,
	TX_CAL_MONTH_ONE,
	TX_CAL_MONTH_TWO,
	TX_CAL_MONTH_THREE,
	TX_CAL_WEEK_ONE,
	TX_ORDERS, 
} from '../../constants/strings';

import { getCurrencyMinorCount } from '../../utils/currency';
import { 
	dateDelta, 
	isDateToday, 
	safeParseInt,
} from '../../utils/general';


export class AnalyticsDataSalesByDate {

	constructor(props) {
		
		if(!props) { props = {}; }

		this.INIT_CONFIG = {
			[ANALYTICS_CONFIG_METRIC]: ANALYTICS_METRIC_TOTAL,
			[ANALYTICS_CONFIG_MODE]: ANALYTICS_SALES_MODE_PRODUCT_LINES,
			[ANALYTICS_CONFIG_SHOW_TODAY]: false,
		};

		this.config = props.config ? Object.assign({}, this.INIT_CONFIG, props.config) : this.INIT_CONFIG;
		this.data = props.data || {};
	}

	getSegmentValue(segmentData) {
		const metric = this.getConfigValue(ANALYTICS_CONFIG_METRIC);
		switch(metric) {
			case ANALYTICS_METRIC_COUNT:
				return segmentData.order_count || 0;
			case ANALYTICS_METRIC_COUNT_ITEMS:
				const totalQty = segmentData.total_quantity || 0;
				const refundQty = segmentData.refund_quantity || 0;
				return Math.max(totalQty - refundQty, 0);
			case ANALYTICS_METRIC_TOTAL:
				if(segmentData.total_sales) {
					const totalSales = segmentData.total_sales || 0;
					const refundSales = segmentData.refund_sales || 0;
					return Math.max(totalSales - refundSales, 0);
				} else if(segmentData.total_cart) {
					const totalCart = parseFloat(segmentData.total_cart / getCurrencyMinorCount()) || 0;
					const totalRefund = segmentData.total_refund ? parseFloat(segmentData.total_refund / getCurrencyMinorCount()) : 0;
					return Math.max(totalCart - totalRefund, 0);
				}
				return 0;
			default:
				return 0;
		}
	}

	getProductLineLabel(permalink, productLines) {
		for(const line of productLines) {
			if(line.permalink === permalink) {
				return line.name;
			}
		}
		return permalink;
	}

	getAllSegments(config = {}) {
		
		const segmentsResp = [];
		const t = config['t'] || null;

		if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_ORDERS) {
			segmentsResp.push(t ? t(TX_ORDERS) : 'Orders');
		} else if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_PRODUCT_LINES) {
			let permalinksObj = {};
			for(const key in this.data) {
				for(const [permalink, lineData] of Object.entries(this.data[key].product_lines)) {
					if(Object.keys(permalinksObj).includes(permalink) === false) {
						permalinksObj[permalink] = this.getSegmentValue(lineData);
					} else {
						permalinksObj[permalink] = permalinksObj[permalink] + this.getSegmentValue(lineData);
					}
				}
			}

			const pairs = Object.entries(permalinksObj);

			const permalinks_asc = pairs.sort((a, b) => b[1] - a[1]).map(pair => pair[0]);
			// const permalinks_desc = pairs.sort((a, b) => a[1] - b[1]).map(pair => pair[0]);

			for(const permalink of permalinks_asc) {
				if(permalinksObj[permalink]) {
					segmentsResp.push(permalink);
				}
			}
		}
		return segmentsResp;
	}

	getStartDate(config = {}) {
		
		const duration = config['duration'] || null;

		try {

			const durationType = duration.charAt(duration.length - 1);
  			const durationValue = safeParseInt(duration.slice(0, -1));

  			const now = new Date();

			const year = now.getFullYear();
			const month = String(now.getMonth() + 1).padStart(2, '0');
			const day = String(now.getDate()).padStart(2, '0');

			const todayValue = `${year}-${month}-${day}`;
			const todayDate = toDate(todayValue);

  			switch(durationType) {
  				case ANALYTICS_DURATION_UNIT_MONTH:
  					return subMonths(todayDate, durationValue);
				case ANALYTICS_DURATION_UNIT_WEEK:
					return subWeeks(todayDate, durationValue);
				default:
					return null;
  			}

		} catch(err) {
			console.error(err);
			return null;
		}
	}

	getLineGraphData(config = {}) {

		const disabledSegments = config['disabledSegments'] || []; // Array of strings; strings are product line NAME
		const productLines = config['productLines'] || [];
		const t = config['t'] || null;

		const allSegments = this.getAllSegments();
		const dataResp = [];

		try {

			let lastDateValue = null;

			const now = new Date();
			// const todayValue = now.toISOString().split('T')[0];

			const year = now.getFullYear();
			const month = String(now.getMonth() + 1).padStart(2, '0');
			const day = String(now.getDate()).padStart(2, '0');

			const todayValue = `${year}-${month}-${day}`;

			const startDate = this.getStartDate(config);

			// Add initial empty dates
			if(startDate && Object.keys(this.data).length) {
				
				const firstDateKey = Object.keys(this.data)[0];
				const firstDate = toDate(firstDateKey.split('T')[0]);

				let initDate = startDate;

				while(initDate < firstDate) {
					for(const segment of allSegments) {
						if(this.getConfigValue(ANALYTICS_CONFIG_SHOW_TODAY) === true || isDateToday(initDate) === false) {
							const segmentValue = this.getProductLineLabel(segment, productLines);
							if(segment === segmentValue || disabledSegments.includes(segmentValue)) {
								continue;
							}
							dataResp.push({
								date: initDate,
								segment: segmentValue,
								value: 0,
							});
						}
					}
					initDate = dateDelta(initDate, 1);
				}
			}


			for(const [key, value] of Object.entries(this.data)) {

				const dateValue = toDate(key.split('T')[0]);

				lastDateValue = dateValue;

				if(this.getConfigValue(ANALYTICS_CONFIG_SHOW_TODAY) === false && isDateToday(dateValue)) {
					continue;
				}

				if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_ORDERS) {				
					dataResp.push({
						date: dateValue,
						segment: t ? t(TX_ORDERS) : 'Orders',
						value: this.getSegmentValue(value),
					});
				} else if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_PRODUCT_LINES) {
					for(const segment of allSegments) {
						
						let dataAdded = false;
						const segmentValue = this.getProductLineLabel(segment, productLines);

						if(segment === segmentValue || disabledSegments.includes(segmentValue)) {
							continue;
						}

						for(const [permalink, lineData] of Object.entries(value.product_lines)) {
							if(segment === permalink) {
								dataResp.push({
									date: dateValue,
									segment: segmentValue,
									value: this.getSegmentValue(lineData),
								});	
								dataAdded = true;
								continue;
							}
						}

						if(!dataAdded) {
							dataResp.push({
								date: dateValue,
								segment: this.getProductLineLabel(segment, productLines),
								value: 0,
							});
						}
					}
				} else {
					// Unsupported mode
					continue;
				}
			}

			// Add supplamental dates with empty lines
			if(lastDateValue) {

				let lastDate = dateDelta(toDate(lastDateValue), 1);
				const todayDate = toDate(todayValue);

				while(lastDate <= todayDate) {
					for(const segment of allSegments) {

						const segmentValue = this.getProductLineLabel(segment, productLines);
						if(segment === segmentValue || disabledSegments.includes(segmentValue)) {
							continue;
						}

						if(this.getConfigValue(ANALYTICS_CONFIG_SHOW_TODAY) === true || isDateToday(lastDate) === false) {
							dataResp.push({
								date: lastDate,
								segment: segmentValue,
								value: 0,
							});
						}
					}
					lastDate = dateDelta(lastDate, 1);
				}
			}
			return dataResp;

		} catch(err) {
			console.error(err);
		}
		return dataResp;
	}

	getAreaGraphData(config = {}) {
		
		const disabledSegments = config['disabledSegments'] || [];
		const productLines = config['productLines'] || [];
		const t = config['t'] || null;

		const allSegments = this.getAllSegments();
		const dataResp = [];

		try {

			let lastDateValue = null;

			const now = new Date();
			// const todayValue = now.toISOString().split('T')[0];

			const year = now.getFullYear();
			const month = String(now.getMonth() + 1).padStart(2, '0');
			const day = String(now.getDate()).padStart(2, '0');

			const todayValue = `${year}-${month}-${day}`;

			const startDate = this.getStartDate(config);

			// Add initial dates
			if(startDate && Object.keys(this.data).length) {
				
				const firstDateKey = Object.keys(this.data)[0];
				const firstDate = toDate(firstDateKey.split('T')[0]);

				let initDate = startDate;

				while(initDate < firstDate) {
					for(const segment of allSegments) {
						if(this.getConfigValue(ANALYTICS_CONFIG_SHOW_TODAY) === true || isDateToday(initDate) === false) {
							const segmentValue = this.getProductLineLabel(segment, productLines);
							dataResp.push({
								date: initDate,
								segment: segmentValue,
								value: 0,
							});
						}
					}
					initDate = dateDelta(initDate, 1);
				}
			}


			for(const [key, value] of Object.entries(this.data)) {

				const dateValue = toDate(key.split('T')[0]);

				lastDateValue = dateValue;

				if(this.getConfigValue(ANALYTICS_CONFIG_SHOW_TODAY) === false && isDateToday(dateValue)) {
					continue;
				}

				if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_ORDERS) {				
					dataResp.push({
						date: dateValue,
						segment: t ? t(TX_ORDERS) : 'Orders',
						value: this.getSegmentValue(value),
					});
				} else if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_PRODUCT_LINES) {
					for(const segment of allSegments) {
						
						let dataAdded = false;
						const segmentValue = this.getProductLineLabel(segment, productLines);

						for(const [permalink, lineData] of Object.entries(value.product_lines)) {
							if(segment === permalink && !disabledSegments.includes(segmentValue)) {
								dataResp.push({
									date: dateValue,
									segment: segmentValue,
									value: this.getSegmentValue(lineData),
								});	
								dataAdded = true;
								continue;
							}
						}

						if(!dataAdded) {
							dataResp.push({
								date: dateValue,
								segment: this.getProductLineLabel(segment, productLines),
								value: 0,
							});
						}
					}
				} else {
					// Unsupported mode
					continue;
				}
			}

			// Add supplamental dates with empty lines
			if(lastDateValue) {

				let lastDate = dateDelta(toDate(lastDateValue), 1);
				const todayDate = toDate(todayValue);

				while(lastDate <= todayDate) {
					for(const segment of allSegments) {
						if(this.getConfigValue(ANALYTICS_CONFIG_SHOW_TODAY) === true || isDateToday(lastDate) === false) {
							const segmentValue = this.getProductLineLabel(segment, productLines);
							dataResp.push({
								date: lastDate,
								segment: segmentValue,
								value: 0,
							});
						}
					}
					lastDate = dateDelta(lastDate, 1);
				}
			}
			return dataResp;

		} catch(err) {
			console.error(err);
		}
		return dataResp;
	}

	getConfigValue(key) {
		return this.config[key] || this.INIT_CONFIG[key];
	}

	setConfigValue(key, value) {
		this.config = Object.assign({}, this.config, { [key]: value });
	}

	getColorObject(config = {}) {

		const productLines = config['productLines'] || [];

		const singleColor = '#46BED9';  // Titan default
		const colorBank = [
			'#8120EF',  // Pink
			'#E98C47',  // Light organge
			'#C4B38A',  // Gold
			'#B75922',  // Burnt orange
			'#4F9FF4',  // Blue, light-ish
			'#9A5D66',  // Puce
			'#4EAB78',  // Green
			'#9F2B24',  // Red
			'#101949',  // Royal blue
			'#5D32AF',  // Purple
		];

		const colorObj = {};
		const segments = this.getAllSegments(config);

		if(segments.length === 1 && !PL_COLORS[segments[0]]) {
			// Return default color if only one segment
			colorObj[this.getProductLineLabel(segments[0], productLines)] = singleColor;
		} else {
			let colorIndex = 0;
			for(const segment of segments) {
				if(PL_COLORS[segment]) {
					colorObj[this.getProductLineLabel(segment, productLines)] = PL_COLORS[segment];
				} else {
					colorObj[this.getProductLineLabel(segment, productLines)] = colorBank[colorIndex];
					colorIndex++;
					if(colorIndex >= colorBank.length) {
						colorIndex = 0;
					}
				}
			}
		}
		return colorObj;
	}

	shouldShowLegend() {
		return this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_PRODUCT_LINES;
	}

	resetConfig() {
		this.config = this.INIT_CONFIG;
	}

	getDurationOptions() {
		const options = [
			{
				display: TX_CAL_WEEK_ONE,
				value: `1${ANALYTICS_DURATION_UNIT_WEEK}`,
			},
			{
				display: TX_CAL_MONTH_ONE,
				value: `1${ANALYTICS_DURATION_UNIT_MONTH}`,
			},
			{
				display: TX_CAL_MONTH_TWO,
				value: `2${ANALYTICS_DURATION_UNIT_MONTH}`,
			},
			{
				display: TX_CAL_MONTH_THREE,
				value: `3${ANALYTICS_DURATION_UNIT_MONTH}`,
			},
		];
		return options;
	}

	getModeOptions() {
		const options = [
			{
				display: TX_ANALYTICS_CONFIG_MODE_VALUE_PRODUCT_LINES,
				value: ANALYTICS_SALES_MODE_PRODUCT_LINES,
			},
			{
				display: TX_ANALYTICS_CONFIG_MODE_VALUE_ORDERS,
				value: ANALYTICS_SALES_MODE_ORDERS,
			},
		];
		return options;
	}

	getMetricOptions() {
		const options = [
			{
				display: TX_ANALYTICS_CONFIG_METRIC_VALUE_TOTAL,
				value: ANALYTICS_METRIC_TOTAL,
			},
			{
				display: TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT_ITEMS,
				value: ANALYTICS_METRIC_COUNT_ITEMS,
			},
		];
		if(this.getConfigValue(ANALYTICS_CONFIG_MODE) === ANALYTICS_SALES_MODE_ORDERS) {
			options.push({
				display: TX_ANALYTICS_CONFIG_METRIC_VALUE_COUNT,
				value: ANALYTICS_METRIC_COUNT,
			});
		}
		return options;
	}
}









