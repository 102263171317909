


import { 
	BLOCK_CONFIG_ALL, 
	BLOCK_TYPE_DASHBOARD_SALES_GRAPH, 
	BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL,
	BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL,
	BLOCK_TYPE_MAGIC_SEALED_VERTICAL,
	BLOCK_TYPE_MAGIC_SINGLES_VERTICAL,
	BLOCK_TYPE_MAGIC_TOP_EDH,
	BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL,
	BLOCK_TYPE_UPCOMING_EVENTS,
} from '../constants/pages';


import BlockDashboardSalesGraph from '../components/Layout/blocks/dashboard/BlockDashboardSalesGraph';
import BlockEventsUpcoming from '../components/Layout/blocks/upcoming-events/BlockEventsUpcoming';
import BlockFeaturedBuylistHorizontal from '../components/Layout/blocks/featured-buylist/BlockFeaturedBuylistHorizontal';
import BlockFeaturedBuylistVertical from '../components/Layout/blocks/featured-buylist/BlockFeaturedBuylistVertical';
import BlockMagicSealedVertical from '../components/Layout/blocks/magic-sealed/BlockMagicSealedVertical';
import BlockMagicSinglesVertical from '../components/Layout/blocks/magic-singles/BlockMagicSinglesVertical';
import BlockMagicTopEDH from '../components/Layout/blocks/magic-top-edh/BlockMagicTopEDH';
import BlockRecentlyAdded from '../components/Layout/blocks/recently-added/BlockRecentlyAdded';




// Page classes are used for pages with user-controled layouts, copy, etc.
// Not used for functional pages (ie, Gallery, Cart, Checkout, etc)
export class Page {

	constructor(props) {

		if(!props) { props = {}; }
		
		// Page attributes
		this.key = props.key || '';
		this.name = props.name || '';
	}
}


export class Layout {

	constructor(props) {

		if(!props) { props = {}; }

		const sectionsArray = props.sections || [];
		const sectionsModels = [];
		for(const section of sectionsArray) {
			sectionsModels.push(new Section(section));
		}
		
		// Layout attributes
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.type = props.type || '';

		this.sections = sectionsModels;
	}
}


export class Section {

	constructor(props) {

		if(!props) { props = {}; }

		const blocksArray = props.blocks || [];
		const blocksModels = [];
		for(const block of blocksArray) {
			blocksModels.push(new Block(block));
		}
		
		// Section attributes
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.type = props.type || '';
		this.animation = props.animation || '';

		this.blocks = blocksModels;
	}
}


export class Block {

	constructor(props) {

		if(!props) { props = {}; }
		
		// Block attributes
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.type = props.type || props.component_key || '';

		// Set config attributes
		this.blockConst = Block.getConst(this.type);

		this.requiredProductLine = this.blockConst && this.blockConst.requiredProductLine ? this.blockConst.requiredProductLine : '';

		this.defaultConfig = this.blockConst ? this.blockConst.defaultConfig : {};
		this.storeConfig = {};
		this.config = Object.assign({}, this.defaultConfig, this.storeConfig);
	}

	get component() {
		switch(this.type) {
			case BLOCK_TYPE_DASHBOARD_SALES_GRAPH:
				return BlockDashboardSalesGraph;
			case BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL:
				return BlockFeaturedBuylistHorizontal;
			case BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL:
				return BlockFeaturedBuylistVertical;
			case BLOCK_TYPE_MAGIC_SEALED_VERTICAL:
				return BlockMagicSealedVertical;
			case BLOCK_TYPE_MAGIC_SINGLES_VERTICAL:
				return BlockMagicSinglesVertical;
			case BLOCK_TYPE_MAGIC_TOP_EDH:
				return BlockMagicTopEDH;
			case BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL:
				return BlockRecentlyAdded;
			case BLOCK_TYPE_UPCOMING_EVENTS:
				return BlockEventsUpcoming;
			default:
				return null;
		}
	}

	get adminComponent() {
		return null;
	}

	static getConst(type) {
		if(!type) { return null; }
		for(const bk of BLOCK_CONFIG_ALL) {
			if(bk.type === type) {
				return bk;
			}
		}
		return null;
	}

	getConfigAttr(configKey) {
		return this.config[configKey];
	}

	isDefaultConfig(configKey) {
		if(!configKey) { return null; }
		return Object.keys(this.defaultConfig).includes(configKey) && !Object.keys(this.storeConfig).includes(configKey);
	}
}





















