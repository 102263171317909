import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../constants/strings';

import { 
  checkoutGetAppliedStoreCredit,
  getCartTaxableGrandTotal,
  checkoutGetIsStoreCreditApplied,
  checkoutGetSelectedShippingMethod, 
  getCartGrandTotalAfterStoreCredit, 
} from '../../utils/checkout';
import { formatPrice } from '../../utils/formatting';
import { getStoreLanguage } from '../../utils/language';

import CartApplyCoupon from './blocks/CartApplyCoupon';

import './style/_cartsummary.scss';

import * as cartActionCreators from '../../actions/cart';
let allActionCreators = Object.assign({}, cartActionCreators);

export class CartSummary extends Component {

  componentDidMount() {
    // Get cart, if one exists
    this.props.cartFetchCart()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });
  }

  getCart() {
    return this.props.cartOverride ? this.props.cartOverride : (this.props.isBuylist ? this.props.cart.currentBuylistCart : this.props.cart.currentCart);
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getShippingCost() {

    if(this.props.isBuylist) { return null; }

    if(this.props.order) {
      // Get shipping cost of previous order
      return this.props.order.totalShipping;
    } else {
      // Get shipping cost of current checkout
      const shippingMethod = checkoutGetSelectedShippingMethod(this.props.checkout.stepData);
      if(!shippingMethod) {
        return null;
      }
      return shippingMethod.cost;   
    }
  }

  getTotalPaid() {

    // Not the true grand total sent to payment; display only
    // For buylist, we look at grand total, not total paid

    if(this.props.isBuylist) { 
      if(this.getCart().totalGraded) {
        return this.getCart().totalGraded;
      }
      return this.getCart().subTotal; 
    }

    if(this.props.order) {
      return this.props.order.totalPaid;
    } else {
      return getCartGrandTotalAfterStoreCredit(this.getCart(), this.props.checkout.stepData);
    }
  }

  getGradingAdjustment() {
    if(!this.props.order || !this.props.order.buylistCart || !this.props.order.buylistCart.totalGraded || !this.props.order.isBuylist) { return 0; }

    return this.props.order.buylistCart.totalGraded - this.props.order.buylistCart.subTotal;
  }

  couponValue() {
    if(this.props.order) {
      return this.props.order.totalCoupon;
    }
    if(!this.props.isBuylist && !this.props.cartOverride) {
      const cart = this.getCart();
      return cart.coupon ? cart.couponDiscount : 0;
    }
    return 0;
  }

  isStoreCreditUsed() {
    if(this.props.order) {
      return this.props.order.totalStoreCredit > 0;
    }
    if(!this.props.isBuylist && !this.props.cartOverride) {
      return checkoutGetIsStoreCreditApplied(this.props.checkout.stepData);
    }
    return false;
  }

  getStoreCreditAmount() {
    if(this.props.order) {
      return this.props.order.totalStoreCredit;
    }
    if(!this.props.isBuylist && !this.props.cartOverride) {
      return checkoutGetAppliedStoreCredit(this.props.checkout.stepData);
    }
    return 0;
  }

  getRefundedAmount() {
    if(this.props.order) {
      return this.props.order.totalRefund;
    }
    return 0;
  }

  getTotalTax() {

    if(this.props.isBuylist) { return 0; }

    if(this.props.order) {
      return this.props.order.totalTax || this.props.order.calculatedTax;
    } else if(this.props.taxRate && !this.props.cartOverride) {
      const totalTaxable = getCartTaxableGrandTotal(this.getCart(), this.props.checkout.stepData);
      return totalTaxable * (this.props.taxRate / 100);
    }

    return 0;
  }

  shouldShowSubtitle() {
    if(!this.props.isBuylist) {
      return true;
    }
    if(this.getCart().totalGraded) {
      return true;
    }
    return false;
  }

  getSubtotal() {
    if(this.props.order && this.props.order.totalCart) {
      return this.props.order.totalCart;
    } else {
      return this.getCart().subTotal;
    }
  }

  render() {

    const {t} = this.props;

    return <div className={this.props.className ? `CartSummary ${this.props.className}` : 'CartSummary'} ref={this.sidebarElement}>
      {this.getCart() ?
        <div className='cartSummaryLiner'>
          {this.shouldShowSubtitle() ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_SUBTOTAL)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getSubtotal(), { addTags: true, language: this.getLanguage() }) }} />
            </div> :
            null
          }

          {this.couponValue() ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_COUPON)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.couponValue(), { addTags: true, language: this.getLanguage() }) }} />
            </div> :
            null
          }

          {!this.props.isBuylist && !this.props.cartOverride ?
            <div className='applyCouponWrapper'>
              <CartApplyCoupon />
            </div> :
            null
          }

          {this.getGradingAdjustment() ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_CART_GRADING_ADJUSTMENT)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getGradingAdjustment(), { addTags: true, language: this.getLanguage() }) }} />
            </div> :
            null
          }

          {this.getShippingCost() !== null ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_SHIPPING)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getShippingCost(), { addTags: true, language: this.getLanguage(), zeroValue: t(tx.TX_FREE) }) }} />
            </div> :
            null
          }

          {this.getTotalTax() ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_TAX)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getTotalTax(), { addTags: true, language: this.getLanguage() }) }} />
            </div> :
            null
          }

          {this.isStoreCreditUsed() ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_MENU_ITEM_STORECREDIT)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getStoreCreditAmount(), { addTags: true, language: this.getLanguage() }) }} />
            </div> :
            null
          }

          <div className='cartSummaryLine'>
            <div className='lineLabel'>{t(tx.TX_TOTAL)}</div>
            <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getTotalPaid(), { addTags: true, language: this.getLanguage() }) }} />
          </div>

          {this.getRefundedAmount() ?
            <div className='cartSummaryLine'>
              <div className='lineLabel'>{t(tx.TX_REFUNDED)}</div>
              <div className='lineValue' dangerouslySetInnerHTML={{ __html: formatPrice(this.getRefundedAmount(), { addTags: true, language: this.getLanguage() }) }} />
            </div> :
            null
          }

        </div> :
        null
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart, 
    checkout: state.checkout, 
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CartSummary));